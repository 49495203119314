import * as moment from 'moment';
import {JsonObject, JsonProperty, JsonType} from 'ta-json';
import {UserShort} from '../../../../models/user';
import {Task} from '../../../task-manager/models/task';
import {
  KPITemplate,
  MetricSource
} from '../../components/matrices/components/matrices-card/components/metrics/add-metrics/add-metrics.component';
import {MatrixUserRespElement} from './matrix-subdivision';
import {MatRadioChange} from '@angular/material/radio';

@JsonObject()
export class StatusTask {
  @JsonProperty('id')
  @JsonType(String)
  id: string;

  @JsonProperty('type')
  @JsonType(String)
  type: string;

  @JsonProperty('name')
  @JsonType(String)
  name: string;

  @JsonProperty('color')
  @JsonType(String)
  color: string;
}
@JsonObject()
export class TaskMatrix {
  @JsonProperty('id')
  @JsonType(String)
  id: String;

  @JsonProperty('vid')
  @JsonType(String)
  vid: String;

  @JsonProperty('status_id')
  @JsonType(String)
  statusId: String;

  @JsonProperty('title')
  @JsonType(String)
  title: String;

  @JsonProperty('author_id')
  @JsonType(String)
  authorId: String;

  @JsonProperty('status')
  @JsonType(StatusTask)
  statusTask: StatusTask;
}

@JsonObject()
export class Motivation {
  @JsonProperty('date_start')
  @JsonType(Date)
  dateStart: Date;

  @JsonProperty('date_finish')
  @JsonType(Date)
  dateFinish: Date;

  @JsonProperty('id')
  @JsonType(Number)
  id: number;

  @JsonProperty('is_approved')
  @JsonType(Boolean)
  isApproved = false;

  @JsonProperty('periodicity')
  @JsonType(String)
  periodicity: string;

  @JsonProperty('salary')
  @JsonType(Number)
  salary: number;

  @JsonProperty('variable_path')
  @JsonType(Number)
  variablePath: number;

  @JsonProperty('motivation_type')
  @JsonType(String)
  type: string;

  @JsonProperty('user_motivation_id')
  @JsonType(Number)
  user_motivation_id?: number;
}

@JsonObject()
export class Employment {
  @JsonProperty()
  @JsonType(String)
  id: string;

  @JsonProperty('motivation')
  @JsonType(Motivation)
  motivation?: Motivation[] = [];

  constructor(id?: any, motivation?: Motivation[]) {
    this.id = id;
    this.motivation = motivation;
  }
}


export class MetricRange {
  @JsonProperty('id')
  @JsonType(Number)
  id?: number;

  @JsonProperty('min')
  @JsonType(Number)
  min?: number;

  @JsonProperty('max')
  @JsonType(Number)
  max: number;

  @JsonProperty('payout')
  @JsonType(Number)
  payout: number;

  @JsonProperty('is_selected')
  @JsonType(Number)
  is_selected: boolean;

  @JsonProperty('matrix_metric_id')
  @JsonType(Number)
  metricId?: number;
}

@JsonObject()
export class MatrixKpi {
  @JsonProperty('id')
  @JsonType(Number)
  id: number;

  @JsonProperty('matrix_id')
  @JsonType(Number)
  matrixId: number;

  @JsonProperty('kpi_template')
  @JsonType(Number)
  template: KPITemplate;

  @JsonProperty('weight')
  @JsonType(Number)
  weight: number;

  @JsonProperty('plan')
  @JsonType(Number)
  plan: number;

  @JsonProperty('fact')
  @JsonType(Number)
  fact: number;

  @JsonProperty('ranges')
  @JsonType(MetricRange)
  ranges: MetricRange[];

  @JsonProperty('description')
  @JsonType(String)
  description: string;
}


@JsonObject()
export class MatrixMetrics {
  @JsonProperty('accrued')
  @JsonType(Number)
  accrued: number;

  @JsonProperty('matrix_kpi_type')
  @JsonType(String)
  type: string;

  @JsonProperty('completed_percents')
  @JsonType(Number)
  completedPercents: number;

  @JsonProperty('motivation_percent')
  @JsonType(Number)
  motivationPercent: number;

  @JsonProperty('starting_price')
  @JsonType(Number)
  startingPrice: number;

  @JsonProperty('id')
  @JsonType(Number)
  id: number;

  @JsonProperty('matrix_id')
  @JsonType(Number)
  matrixId: number;

  @JsonProperty('metric')
  @JsonType(Number)
  metric: MetricSource;

  @JsonProperty('kpi_template')
  @JsonType(Number)
  template: MetricSource;

  @JsonProperty('weight')
  @JsonType(Number)
  weight: number;

  @JsonProperty('plan')
  @JsonType(Number)
  plan: number;

  @JsonProperty('fact')
  @JsonType(Number)
  fact: number;

  @JsonProperty('ranges')
  @JsonType(MetricRange)
  ranges: MetricRange[];

  @JsonProperty('range_unit')
  @JsonType(String)
  units: string;

  @JsonProperty('description')
  @JsonType(String)
  description: string;

  @JsonProperty('title')
  @JsonType(String)
  title: string;

  @JsonProperty('kpi_type')
  @JsonType(String)
  kpi_type: string;

  calc: any;

  static getNormVal(metric: MatrixMetrics, point?) {
    return metric.plan / 100 * point;
  }

  static getPayout(metric: MatrixMetrics, point?) {
    let percent = MatrixMetrics.getProgressPercent(metric) * 100;

    if (point) {
      percent = point / metric.fact * 100;
    }

    const needle = metric.units === 'percents' ? percent : metric.fact;

    if (isNaN(percent) || !isFinite(percent)) {
      return 0;
    }

    if (metric.ranges.length === 0) {
      return 0;
    }

    for (let i = 0; i < metric.ranges.length; ++i) {
      const r = metric.ranges[i];

      let found = false;
      if (i === metric.ranges.length - 1) {
        found = true;
      } else {
        const next = metric.ranges[i + 1];
        const max = metric.ranges[i].max;

        if (needle <= max) {
          found = true;
        }
      }

      if (found) {
          let payoutPercent = r.payout;

          if (r.is_selected) {
            payoutPercent = percent;
          }

          let startingPrice = metric.startingPrice;

          if (metric.type === 'additional_percents') {
            startingPrice = metric.fact * (metric.startingPrice / 100);
          }

          return payoutPercent / 100 * startingPrice;
      }
    }
  }

  static  getProgressPercent(metric) {
    const p = metric.plan ? metric.plan : 1;
    const percent = metric.fact / p;

    if (isNaN(percent)) {
      return 0;
    }

    return percent;
  }

  static calcLowerBound(metric: MatrixMetrics, r: MetricRange) {
    const percent = Math.round(r.min / metric.plan);

    if (isNaN(percent)) {
      return 0;
    }

    return percent;
  }

  static calcUpperBound(metric: MatrixMetrics, r: MetricRange) {
    const percent = Math.round(r.max / metric.plan) ;

    if (isNaN(percent)) {
      return 0;
    }

    return percent;
  }
}

@JsonObject()
export class UserMatrix extends UserShort {
  @JsonProperty('photo')
  @JsonType(String)
  photo?: string;

  constructor(userShort?: UserShort, photo: string = '') {
    super(
      userShort.id,
      userShort.firstName,
      userShort.middleName,
      userShort.lastName,
      userShort.position,
      userShort.currentPosition
    );
    this.photo = photo;
  }
}
@JsonObject()
export class MatrixSmartTasks {
  @JsonProperty('accrued')
  @JsonType(Number)
  accrued: number;

  @JsonProperty('is_transferred')
  @JsonType(Boolean)
  isTransferred: boolean;

  @JsonProperty('motivation_percent')
  @JsonType(Number)
  motivationPercent: number;

  @JsonProperty('planned_price')
  @JsonType(Number)
  plannedPrice: number;

  @JsonProperty('quality')
  @JsonType(Number)
  quality: number;

  @JsonProperty('starting_price')
  @JsonType(Number)
  startingPrice: number;

  @JsonProperty('task')
  @JsonType(Task)
  task: Task;

  @JsonProperty('task_id')
  @JsonType(Number)
  taskId: number;

  @JsonProperty('tax')
  @JsonType(Number)
  tax: number;

  @JsonProperty('weight')
  @JsonType(Number)
  weight: number;

  constructor(matrixDetails: MatrixSmartTasks) {

  }

  getBasicSum() {
    return this.startingPrice;
  }

  getSumAfterPenalty() {
    const basic = this.getBasicSum();
    return (basic - basic / 100 * this.tax) || 0;
  }

  getSumAfterQualityPenalty() {
    const pen = this.getSumAfterPenalty();
    const result = pen - pen / 100 * (100 - (this.quality || 0));

    if (result < 0) {
      return 0;
    }

    return result;
  }
}

export class MatrixCoord {
  @JsonProperty('approve_fact')
  @JsonType(Boolean)
  approveFact: boolean;
  @JsonProperty('approve_plan')
  @JsonType(Boolean)
  approvePlan: boolean;
  @JsonProperty('id')
  @JsonType(Number)
  id: number;
  @JsonProperty('matrix_id')
  @JsonType(Number)
  matrix_id: number;
  @JsonProperty('status')
  @JsonType(String)
  status: string;
  @JsonProperty('user')
  @JsonType(UserShort)
  user: UserShort;
  @JsonProperty('user_id')
  @JsonType(Number)
  userId: number;
}
@JsonObject()
export class MatrixStat {
  @JsonProperty('completed_KPI')
  @JsonType(Number)
  completedKPI: number;

  @JsonProperty('expired_tasks_count')
  @JsonType(Number)
  expired_tasks_count: number;

  @JsonProperty('completed_tasks_count')
  @JsonType(Number)
  completedSmartTask: number;

  @JsonProperty('planed_price_percent')
  @JsonType(Number)
  planedPricePercent: number;

  @JsonProperty('variable_salary')
  @JsonType(Number)
  variableSalary: number;
}

export class MatrixUpdateEvent {
  user: UserShort;
  empId: number;
  matrices: MatrixUserRespElement[];
}

@JsonObject()
export class Matrix {
  @JsonProperty('matrix_smart_task_percent')
  @JsonType(Number)
  matrixSmartTaskPercent: number;

  @JsonProperty('can_approve')
  @JsonType(Boolean)
  canApprove?: boolean;

  @JsonProperty('matrix_kpi_percent')
  @JsonType(Number)
  matrixKpiPercent?: number;

  @JsonProperty('related_matrix')
  @JsonType(Number)
  related_matrix?: Matrix[];

  @JsonProperty('total_accrued')
  @JsonType(Number)
  total_accrued?: number;

  @JsonProperty('start')
  @JsonType(Date)
  start?: Date;

  @JsonProperty('finish')
  @JsonType(Date)
  finish?: Date;

  @JsonProperty('salary')
  @JsonType(Number)
  salary?: number;

  @JsonProperty('variable_salary')
  @JsonType(Number)
  variableSalary?: number;

  @JsonProperty('user_start_approve')
  @JsonType(Boolean)
  userStartApprove?: boolean = false;

  @JsonProperty('user_finish_approve')
  @JsonType(Boolean)
  userFinishApprove?: boolean = false;

  @JsonProperty('coordinator_start_approve')
  @JsonType(Boolean)
  coordinatorStartApprove?: boolean = false;

  @JsonProperty('coordinator_finish_approve')
  @JsonType(Boolean)
  coordinatorFinishApprove?: boolean = false;

  @JsonProperty('plan')
  @JsonType(Number)
  plan?: number;

  @JsonProperty('norm_hours')
  @JsonType(Number)
  normo: number;

  @JsonProperty('fact')
  @JsonType(Number)
  fact?: number;

  @JsonProperty('is_active')
  @JsonType(Boolean)
  isActive?: boolean;

  @JsonProperty('approve_plan')
  @JsonType(Boolean)
  approvePlan?: boolean;

  @JsonProperty('approve_fact')
  @JsonType(Boolean)
  approveFact?: boolean;

  @JsonProperty('is_archive')
  @JsonType(Boolean)
  isArchive?: boolean;

  @JsonProperty('attention')
  @JsonType(Boolean)
  attention?: boolean;

  @JsonProperty('id')
  @JsonType(Number)
  id?: number;

  @JsonProperty('matrix_smart_tasks')
  @JsonType(MatrixSmartTasks)
  matrixSmartTasks: MatrixSmartTasks[] = [];

  @JsonProperty('additional_smart_tasks')
  @JsonType(MatrixSmartTasks)
  matrixSmartTasksAdditional: MatrixSmartTasks[] = [];

  @JsonProperty('matrix_kpi')
  @JsonType(MatrixMetrics)
  matrixMetrics: MatrixMetrics[] = [];

  // @JsonProperty('matrix_kpi')
  // @JsonType(MatrixKpi)
  // matrixKpi: MatrixKpi[] = [];

  @JsonProperty('user')
  @JsonType(UserMatrix)
  user?: UserMatrix;

  @JsonProperty('motivation')
  @JsonType(Motivation)
  motivation: Motivation;

  @JsonProperty('stat')
  @JsonType(MatrixStat)
  stat: MatrixStat;

  @JsonProperty('motivation_id')
  @JsonType(String)
  motivationId: string;

  @JsonProperty('subdivision_id')
  @JsonType(String)
  subdivisionId: string;

  @JsonProperty('status')
  @JsonType(String)
  status: string;


  @JsonProperty('coordinators')
  @JsonType(MatrixCoord)
  coordinators: MatrixCoord[];

  @JsonProperty('user_motivation_id')
  @JsonType(Number)
  user_motivation_id?: number;

  prev?: number;
  next?: number;

  prevDot?: boolean;
  nextDot?: boolean;

  cabinetOpen?: boolean;

  @JsonProperty('additional_matrix_kpi')
  @JsonType(MatrixMetrics)
  matrixMetricsAddFixed: MatrixMetrics[] = [];

  @JsonProperty('additional_percents_matrix_kpi')
  @JsonType(MatrixMetrics)
  matrixMetricsAddPercent: MatrixMetrics[] = [];

  @JsonProperty('settings')
  @JsonType(Object)
  settings: any;

  @JsonProperty('smart_task')
  @JsonType(MatrixSmartTasks)
  smartTask?: MatrixSmartTasks;

  @JsonProperty('completed_kpi_percent')
  @JsonType(Number)
  completedKpiPercent: number;

  @JsonProperty('part_salary_type')
  @JsonType(String)
  partSalaryType: string;

  @JsonProperty('can_edit')
  @JsonType(Boolean)
  canEdit: boolean;

  getLeftOver() {
    if (this.partSalaryType === 'PERCENTS') {
      let leftOverPercent = 100 - this.matrixSmartTaskPercent - this.matrixKpiPercent;
      if (leftOverPercent < 0) {
        leftOverPercent = 0;
      }

      return this.variableSalary * leftOverPercent / 100;
    } else {
      return this.variableSalary - this.matrixSmartTaskPercent - this.matrixKpiPercent;
    }
  }

  getPlanRatio() {
    if (this.motivation?.type === 'CONSTANT') {
      return this.plan / this.normo;
    } else {
      return 1;
    }
  }

  getFactRatio() {
    if (this.motivation?.type === 'CONSTANT') {
    return this.fact / this.plan;
      } else {
      return 1;
    }
  }

  getPlanConstantSalary() {
    return this.salary * this.getPlanRatio();
  }

  getFactConstantSalary() {
    return this.getPlanConstantSalary() * this.getFactRatio();
  }

  getPlanVariableSalary() {
    return this.variableSalary * this.getPlanRatio();
  }

  getFactVariableSalary() {
    let sumTask = 0;
    for (const t of this.matrixSmartTasks) {
      sumTask += t.accrued;
    }

    let sumTaskAdd = 0;
    for (const t of this.matrixSmartTasksAdditional) {
      sumTaskAdd += t.accrued;
    }

    let sumMetric = 0;
    for (const m of this.matrixMetrics) {
      sumMetric += m.accrued;
    }

    let sumMetricAddPercent = 0;
    for (const m of this.matrixMetricsAddPercent) {
      sumMetricAddPercent += m.accrued;
    }

    let sumMetricAddFixed = 0;
    for (const m of this.matrixMetricsAddFixed) {
      sumMetricAddFixed += m.accrued;
    }

    const varPart = sumTask + sumMetric + sumTaskAdd + sumMetricAddPercent + sumMetricAddFixed;

    return varPart + this.getLeftOver() * this.getFactRatio();
  }

  getPlanTotalSalary() {
    return this.getPlanConstantSalary() + this.getPlanVariableSalary();
  }

  getFactTotalSalary() {
    return this.getFactConstantSalary() + this.getFactVariableSalary();
  }


  get smartTasks() {
    return this.matrixSmartTasks ? this.matrixSmartTasks : [];
  }

  private static capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getPeriodicity(matrix: Matrix) {
    moment.locale('ru');

    let periodicity = '';

    const monthly = moment(matrix.finish).diff(moment(matrix.start), 'days') >= 27;

    if (monthly) {


      periodicity = Matrix.capitalizeFirstLetter(moment(matrix.start).format('MMMM'));
    } else {
      const periodFirst = moment(matrix.start).format('DD MMM').split('.');
      const periodSecond = moment(matrix.finish).format('DD MMM').split('.');

      periodicity = periodFirst[0] + ' - ' + periodSecond[0];
    }

    return periodicity;
  }

  // считает проценты
  calculatePercentage() {
    const tasks = this.matrixSmartTasks;
    const persentArray = [];
    const maxPercentFunc = () => Math.max.apply(null, persentArray);
    const sum = tasks.reduce(
        (accumulator, currentValue) => accumulator + currentValue.weight,
        0
    );
    tasks.forEach((element) => {
      const percent = ((element.weight * 100) / sum).toFixed(1);
      persentArray.push(percent);
    });
    // считаем общий процент.
    let sumPercent = persentArray.reduce(
        (accumulator, currentValue) => accumulator + parseFloat(currentValue),
        0
    );
    if (sumPercent !== 100.0) {
      sumPercent = Math.round(sumPercent * 10) / 10;
      const missingPercent = Math.round((100.0 - sumPercent) * 10) / 10;
      // индекс наибольшего процента в задачах.
      const maxPercent = maxPercentFunc();
      let index = persentArray.findIndex(
          (el) => el === maxPercent.toString()
      );
      let newPercent;
      if (index === -1) {
        // добавляем недостающий процент
        index = 0;
        newPercent = missingPercent + parseFloat(persentArray[0]);
      } else {
        // добавляем недостающий процент
        newPercent = missingPercent + maxPercent;
      }
      persentArray.splice(index, 1, newPercent);
    }

    for (let i = 0; i < persentArray.length; i++) {
      this.matrixSmartTasks[i]['percent'] = persentArray[i];
    }
  }

  getTaskPrice(st: MatrixSmartTasks) {
    const variablePart = this.variableSalary || 0;
    let percent = this.matrixSmartTaskPercent || 0;

    const total = variablePart / 100 * percent;
    const task = this.matrixSmartTasks.find(s => s.taskId === st.taskId);


    if (!task['percent']) {
      this.calculatePercentage();
    }

    return parseFloat(task['percent']) / 100 * total;
  }

  agreedPlan(mat: Matrix, who: string) {
    return mat?.coordinators?.find(u => u.approvePlan && u.status === who);
  }

  agreedFact(mat: Matrix, who: string) {
    return mat?.coordinators?.find(u => u.approveFact && u.status === who);
  }

  agreedPlanAll(mat: Matrix) {
    const agreedEmployee = this.agreedPlan(mat, 'employee');
    const agreedExec =  this.agreedPlan(mat, 'executive');

    return agreedEmployee && agreedExec;
  }

  agreedFactAll(mat: Matrix) {
    const agreedEmployee = this.agreedFact(mat, 'employee');
    const agreedExec =  this.agreedFact(mat, 'executive');

    return agreedEmployee && agreedExec;
  }



  getTaskNumber() {
    if (this.isRadioSelected('PERCENTS')) {
      const result =  this.matrixSmartTaskPercent * this.variableSalary / 100;
      return Math.round(result);
    } else {
      return +this.matrixSmartTaskPercent;
    }
  }

  getKpiNumber() {
    if (this.isRadioSelected('PERCENTS')) {
      const result =  this.matrixKpiPercent * this.variableSalary / 100;
      return Math.round(result);
    } else {
      return +this.matrixKpiPercent;
    }
  }

  getPercentRadioValue() {
    return this.partSalaryType;
  }

  getTaskPercents() {
    if (this.isRadioSelected('PERCENTS')) {
      return +this.matrixSmartTaskPercent;
      } else {
        if ( this.matrixSmartTaskPercent == 0) {
          return 0;
        }

        return Math.round((this.matrixSmartTaskPercent / this.variableSalary) * 100);
    }
  }

  getKpiPercents() {
    if (this.isRadioSelected('PERCENTS')) {
      return +this.matrixKpiPercent;
    } else {
      if ( this.matrixKpiPercent == 0) {
        return 0;
      }

      return Math.round((this.matrixKpiPercent / this.variableSalary) * 100);
    }
  }

  isRadioSelected(needle: string) {
    return this.partSalaryType === needle;
  }

  onRadioChange(value) {
    if (!this.isRadioSelected('PERCENTS')) {
      this.matrixSmartTaskPercent = this.getTaskPercents();
      this.matrixKpiPercent = this.getKpiPercents();
    } else {
      this.matrixSmartTaskPercent = this.getTaskNumber();
      this.matrixKpiPercent = this.getKpiNumber();
    }

    this.partSalaryType = value;
  }

  isAttentionBorderShownFor() {
    if (this.isActive === false) {
      return false;
    }

    if (this.status === MatrixStatuses.NEED_APPROVE_FACT.id || this.status === MatrixStatuses.ON_APPROVE_FACT.id) {
      return true;
    }

    if (this.status === MatrixStatuses.NEED_APPROVE_PLAN.id || this.status === MatrixStatuses.ON_APPROVE_PLAN.id) {
      return true;
    }

    return false;
  }

  isEmpty() {
    return this.matrixSmartTasks.length === 0
        && this.matrixSmartTasksAdditional.length === 0
        && this.matrixMetrics.length === 0
        && this.matrixMetricsAddFixed.length === 0
        && this.matrixMetricsAddPercent.length === 0;
  }
}

@JsonObject()
export class MatrixSetting {

  @JsonProperty('auto_matrix_creation_period')
  @JsonType(Number)
  autoMatrixCreationPeriod?: number;

  @JsonProperty('allow_task_transfer')
  @JsonType(Boolean)
  allowTaskTransfer?: boolean;

  @JsonProperty('time_to_edit_period')
  @JsonType(Number)
  timeToEditPeriod?: number;

}

export const MatrixStatuses = {
  'DRAFT': {
    id: 'DRAFT',
    title: 'Черновик',
    color: '_grey',
    addTasksEmployee: true,
    addTasksExecutive: true,
    showFact: false,
    showDone: false,
    editMode: true,
  },
  'NEED_APPROVE_PLAN': {
    id: 'NEED_APPROVE_PLAN',
    title: 'Согласовать план',
    color: '_red',
    addTasksEmployee: true,
    addTasksExecutive: true,
    showFact: false,
    showDone: false,
    editMode: true,
  },
  'ON_APPROVE_PLAN': {
    id: 'ON_APPROVE_PLAN',
    title: 'Согласование плана',
    color: '_red',
    addTasksEmployee: true,
    addTasksExecutive: true,
    showFact: false,
    showDone: false,
    editMode: true,
  },
  'NEED_APPROVE_FACT': {
    id: 'NEED_APPROVE_FACT',
    title: 'Согласовать факт',
    color: '_red',
    addTasksEmployee: false,
    addTasksExecutive: true,
    showFact:  true,
    showDone:  true,
    editMode: false,
  },
  'ON_APPROVE_FACT': {
    id: 'ON_APPROVE_FACT',
    title: 'Согласование факта',
    color: '_red',
    addTasksEmployee: false,
    addTasksExecutive: true,
    showFact: true,
    showDone:  true,
    editMode: false,
  },
  'ACTIVE': {
    id: 'ACTIVE',
    title: 'Действующая',
    color: '_green',
    addTasksEmployee: false,
    addTasksExecutive: true,
    showFact: true,
    showDone:  true,
    editMode: false,
  },
  'COMPLETED': {
    id: 'COMPLETED',
    title: 'Завершена',
    color: '_grey',
    showFact: true,
    addTasksEmployee: false,
    addTasksExecutive: false,
    showDone:  true,
    editMode: false,
  }
};
